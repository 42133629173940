.primary-gradient{
    background: linear-gradient(90deg, #623FA2 0%, #2C0A49 100%);
}
.secondary-gardient{
    background: linear-gradient(90deg, #01072C 0%, #014B8D 100%);
}
.gradient-box{
    color: #fff;
    border-radius: 30px;
    text-align: center;
    width: fit-content;
    padding: 5px 20px;
}
.viewIcon svg path{
    fill: #55ded0 !important;
}
.set-width-table .MuiTableRow-root  .MuiTableCell-root:first-child{
    width: 200px;
    min-width: 200px;
}
.set-text-graph .MuiGrid-root.MuiGrid-item,
.set-text-graph-sub .MuiGrid-root.MuiGrid-item{
    flex: 0 0 100%;
}
.order-details .MuiTableContainer-root{
    height: 320px;
    overflow: auto;
}
.tableBgTransparent .MuiTableContainer-root, .tableBgTransparent .tablewithoutBorder{
    background-color: transparent !important;
}
.set-text-graph .MuiBox-root {
    justify-content: left;
}
.set-text-graph{
    width: 100%;
    margin: auto;
}
.set-text-graph-sub{
    width: 40%;
    margin: auto;
}
.header-title{
    font-weight: 600;
}
.setDatepicker .rs-picker-daterange .rs-picker-toggle.rs-btn,
.setDatepicker .rs-picker-toggle-wrapper{
    width: 100% !important;
}
.focusRemoveInput .MuiInputBase-input.MuiOutlinedInput-input{
    border: 0 !important;
}
.didTableSet .MuiTableCell-head{
    border-top: 0 !important;
}
.didTableSet .MuiTable-root{
    border-radius: 20px;
}
.didTableSet .MuiTableContainer-root {
    border-radius: 20px;
    max-height: 500px;
}
.didTableSet .MuiTableHead-root{
    border-radius: 20px 20px 0 0;
}
.didTableSet .MuiInputBase-root input{
    padding-left: 25px;
}
.setInput.didTableSet .MuiInputBase-root input{
    padding-left: 10px;
}
.MuiStepLabel-iconContainer{
    padding-right: 0 !important;
    position: absolute;
    top: 10px;
    z-index: 1;
    left: 7px;
    color: #55DED0;
    display: none;
}
.MuiStepLabel-iconContainer svg.Mui-completed{
    width: 20px;
    height: 20px;
    display: block;
    left: 0;
    top: 1px;
}
.MuiStepLabel-iconContainer.Mui-completed{
    display: block;
    width: 20px;
    height: 20px;
}
.width-number{
    width: 120px
}
.width-number{
    width: 120px
}
.width-number{
    width: 120px
}
.set-text-graph-sub-100{
    width: 100%;
}
.MuiTableContainer-root{
    clear: both !important;
    z-index: 2;
}
.MuiTable-root .MuiIconButton-sizeMedium{
    padding: 8px 4px;
}
.MuiTable-root .acc_status .MuiIconButton-sizeMedium{
    padding: 1px;
} 
.w-180{
    width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.MuiTableCell-root a.link-set{
    color:#364FB3 !important;
    text-decoration: underline !important;
}
.link-set{
    color:#364FB3 !important;
    text-decoration: underline !important;
    font-weight: 500;
    cursor: pointer;
}
.radius-set{
    border-radius: 50% !important;
    object-fit: contain;
}
.linkSet, .linkSet:hover{
    border: 0;
    border-bottom: 1px solid #364fb3;
    padding: 0;
    border-radius: 0;
    height: auto;
    line-height: normal;
    display: block;
    min-height: auto;
    font-weight: 500;
    font-size: 14px;
}
.width-set{
    width: 120px !important;
}
.width-set-bank{
    width: 180px !important;
}
.company-image-upload .MuiFormControl-root {
    display: none;
}
.company-image-upload .MuiButton-containedPrimary {
    color: #364FB3;
    text-decoration: underline;
    background-color: transparent;
    padding: 0 10px;
    min-height: 20px;
}
.company-image-upload .MuiButton-containedPrimary:hover{
    color: #364FB3;
    text-decoration: underline;
    background-color: transparent !important;
}
.company-image-upload .MuiButton-startIcon {
    display: none;
}
.comapny-image-set{
    width: 60px;
    height: 60px;
    object-fit: cover;
    border-radius: 50%;
}
.company-preview-set{
    width: 45px;
    height: 45px;
    object-fit: cover;    
}
.setHorizontalTab{
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid #55DED0;
    padding: 8px;
    border-radius: 10px;
    width: fit-content;
    margin: 10px 0;
}
.setHorizontalTab .MuiTabs-flexContainer {
    gap: 10px;
    overflow-x: auto;
    width: 100%;
    padding-bottom: 5px;
    padding-top: 5px;
    border-radius: 0;
}
.setHorizontalTab .MuiButtonBase-root.Mui-selected{
    background-color: #55DED0 !important;
    color: #2B2B2B !important;
    border-radius: 5px !important;
} 
.setHorizontalTab .MuiButtonBase-root{
    background-color: #F2F4FF !important;
    color: #2B2B2B;
    border-radius: 5px;
    font-size: 14px !important;
    font-weight: 500;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 0;
    width: auto;
    min-width: auto !important;
    max-width: max-content !important;
}
/* .setHorizontalTab .MuiTabScrollButton-horizontal {
    display: block !important;
    opacity: 1;
    background-color: transparent !important;
    top: 5px;
    cursor: pointer;
} */
.search-border .MuiAccordion-gutters{
    border: 1px solid #E9E9E9 !important;
    box-shadow: none !important;
}
.search-border .MuiAccordion-gutters::before{
    display: none !important;
}
.w-95{
    width: 100%;
}
.input-adorment p{
    font-size: 15px !important;
    color: #151515 !important;
}
.set_symbol {
    position: relative;
}
.set_symbol .MuiButtonBase-root{
    background: #f2f4ff;
    border-radius: 0.5rem 0 0 0.5rem;
    padding: 13px 0px 13px 0px;
    width: 25px;
}
.set_symbol .MuiInputBase-input{
    border-radius: 0 0.5rem 0.5rem 0;
}
.set_symbol .MuiFormControl-root {
    width: 100%;
}
.set_symbol .MuiFormHelperText-root{
    margin-right: 0;
    margin-left: -20px;
    color:#fc3c3c;
}
.set-error-text{
    color: #fc3c3c !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}
.add-error{
    line-height: 1.25;
}
.red-text{
    color:#fc3c3c;
}
.setDefaultButton {
    position: absolute;
    left: 0px;
    top: 0px;
    padding: 3px 8px !important;
    font-size: 12px !important;
    color: #fff !important;
    min-height: auto;
    min-width: auto;
    border-radius: 0px 0px 6px 0px;
}
.remove-btn{
    color: #fff !important;
    height: 0 !important;
    min-height: 0 !important;
    width: 0 !important;
    min-width: 0 !important;
    padding: 12px 10px 9px 10px;
    border-radius: 0 !important;
    margin: 10px;
    background: #C02B0A !important;
}
:focus-visible, :focus{
    outline: none !important;
}
.MuiInputBase-input-MuiOutlinedInput-input:focus{
    padding-left: 5px !important;
    background-color: inherit !important;
}
@media only screen and (min-width: 1400.25px) {
    .width-set{
        width: 140px !important;
    }
    .width-set-bank{
        width: 210px !important;
    }
}
@media only screen and (min-width: 1440px) {
    .width-number{
        width: 140px
    }
}
@media only screen and (max-width: 1366.25px) {
    .set-text-graph-sub {
      width: 80%;
    }
    .set-text-graph-sub-100{
        width: 100%;
    }
}
@media only screen and (max-width: 991.25px) {
    .set-text-graph-sub {
      width: 100%;
    }
    .setHorizontalTab{
        width: 100%;
    }
}
@media only screen and (max-width: 768.25px) {
    .MuiBreadcrumbs-separator{
        display: none;
    }
    .MuiBreadcrumbs-ol .MuiBreadcrumbs-li {
        display: none;
    }
    .MuiBreadcrumbs-ol .MuiBreadcrumbs-li:first-child {
        display: flex;
    }
}